<template>
    <div class="hpctemplate">
        <section class="body1200" style="margin-top: 90px;">
            <div class="txt60" style="margin-top: 30px">HPC智慧园区</div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
                园区指一般由政府（民营企业与政府合作）规划建设的，供水、供电、供气、通讯、道路、仓储及其它配套设施齐全、布局合理且能够满足从事某种特定行业生产和科学实验需要的标准性建筑物或建筑物群体，“包括工业园区、产业园区、物流园区、都市工业园区、科技园区、创意园区等。”
            </div>
        </section>
        <section class="body1200" style="margin-top: 160px;">
            <div  class="txt42">
                整体解决方案
            </div>
            <div class="txt18" style="margin-top: 30px;width:860px;">
                利用物联网、云计算、大数据、人工智能等先进技术，帮助园区打造基础设施集成、运营管理 高效、公共服务便捷、可成长、可扩充、面向未来持续发展的智慧园区平台，构筑园区建设、运营、服务多层次生态链共聚。
            </div>
            <div class="hpc_fa1" style="margin-top: 60px;">
            </div>
        </section>
        <section class="body1200" style="margin-top: 160px;">
            <div  class="txt42">
                理念与架构
            </div>
            <div class="hpc_fa2" style="margin-top: 60px;">
            </div>
        </section>

        <section class="body1200" style="margin-top: 160px;">
            <div  class="txt42">
                方案展示
            </div>

            <div class="htable" style="margin-top: 30px;justify-content: center;width: 860px;border-bottom: 1px solid #e7e7e7;">
                <div class="hcell">
                    <span v-if="hpcgn==1" class="txtH16 btLine">安防监控</span>
                    <span v-else class="txtH16 ghblockclick" @click="hpcgn=1">安防监控</span>
                </div>
                <div class="hcell " style="margin-left: 60px">
                    <span v-if="hpcgn==2" class="txtH16 btLine">园区管理</span>
                    <span v-else class="txtH16 ghblockclick" @click="hpcgn=2">园区管理</span>
                </div>
                <div class="hcell " style="margin-left: 60px">
                    <span v-if="hpcgn==3" class="txtH16 btLine">智慧政务</span>
                    <span v-else class="txtH16 ghblockclick" @click="hpcgn=3">智慧政务</span>
                </div>
                <div class="hcell " style="margin-left: 60px">
                    <span v-if="hpcgn==4" class="txtH16 btLine">企业服务</span>
                    <span v-else class="txtH16 ghblockclick" @click="hpcgn=4">企业服务</span>
                </div>
                <div class="hcell " style="margin-left: 60px">
                    <span v-if="hpcgn==5" class="txtH16 btLine">智慧生活</span>
                    <span v-else class="txtH16 ghblockclick" @click="hpcgn=5">智慧生活</span>
                </div>

            </div>
        </section>
        <section class="body1200 hpc_ghblock" v-if="hpcgn==1">
            <div  class="txt42">
                安防监控
            </div>
            <imgblock_three :hrow="imgblock_three11"  style="margin-top: 60px; " />
            <gnmrow :brow="gnmrow11"  style="margin-top: 60px;"> </gnmrow>
            <gnmrow :brow="gnmrow12"  style="margin-top: 60px;"> </gnmrow>
        </section>
        <section class="body1200 hpc_ghblock" v-if="hpcgn==2">
            <div  class="txt42">
                园区管理
            </div>
            <imgblock_three :hrow="imgblock_three21"  style="margin-top: 60px; " />
            <gnmrow :brow="gnmrow21"  style="margin-top: 60px;"> </gnmrow>
            <gnmrow :brow="gnmrow22"  style="margin-top: 60px;"> </gnmrow>
            <gnmrow :brow="gnmrow23"  style="margin-top: 60px;"> </gnmrow>
        </section>
        <section class="body1200 hpc_ghblock" v-if="hpcgn==3">
            <div  class="txt42">
                智慧政务
            </div>
            <div class="txt18" style="margin-top: 30px;width:860px;">
                利用物联网、云计算、大数据、人工智能等先进技术，帮助园区打造基础设施集成、运营管理 高效、公共服务便捷、可成长、可扩充、面向未来持续发展的智慧园区平台，构筑园区建设、运营、服务多层次生态链共聚。

            </div>
        </section>
        <section class="body1200 hpc_ghblock" v-if="hpcgn==4">
            <div  class="txt42">
                企业服务
            </div>
            <div class="txt18" style="margin-top: 30px;width:860px;">
                支撑园区入驻企业的基本信息 管理，包括基本信息的录入、 修改、查询及导入/导出功能， 以及刻画企业能力；辅助政府实时对园区企业各维度数据进行分析判断。
            </div>
        </section>
        <section class="body1200 hpc_ghblock" v-if="hpcgn==5">
            <div  class="txt42">
                智慧生活
            </div>
            <imgblock_three :hrow="imgblock_three51"  style="margin-top: 60px; " />
            <gnmrow :brow="gnmrow51"  style="margin-top: 60px;"> </gnmrow>
        </section>




        <section class="body1200" style="margin-top: 160px;">
            <div style="font-size: 42px;">
                方案优势
            </div>
            <div class="txtzw" style="width: 860px;margin-top: 60px;">
                辉腾光伏能源通过将信息技术、互联网技术与光伏技术跨界融合，集成高效光伏发电，高压储能，实现可视化家庭能源管理，场景化、简单化一键用电，为客户带来更安全、多发电、好管理的美好生活体验。
            </div>
            <div style="margin-top: 56px;">
                <img :src="hpc_ys">
            </div>
        </section>

        <section class="body1200" style="margin-top: 160px;">
            <div style="font-size: 42px;">
                相关案例
            </div>
            <al_three :hrow="alrows" />
        </section>



    </div>

</template>
<script>
    import imgblock_three from "./control/imgblock_three.vue"
    import gnmrow from "./control/gn_m_row.vue"
    import al_three from "./control/al_three.vue"
    export default {
        data () {
            return {
                hpcgn: 1,

                imgblock_three11:[
                    {tb_image:require('../assets/hpc_gn11.jpg')},
                    {tb_image:require('../assets/hpc_gn12.jpg')},
                    {tb_image:require('../assets/hpc_gn13.jpg')},
                ],
                gnmrow11:[
                    {tb_image:require('../assets/hpc_gn14.png'),tb_title:'视频监控',tb_summary:'辉腾能源视频监控应用主要包括云存储服务，云视频分发与管理服务，还包括人脸识别、人流量密度分析。析、卡口管理等管理，在这些应用中辉腾一直与华为进行深度合作。'},
                    {tb_image:require('../assets/hpc_gn15.png'),tb_title:'门禁管理',tb_summary:'集通道安全管理、自动化控制、报警处理、事件记录、多系统联 动为一体，具有主动安全防范功能。'},
                    {tb_image:require('../assets/hpc_gn16.png'),tb_title:'停车管理',tb_summary:'车辆信息管理，固定用户与临停用户验证与放行管理。'},
                ],

                imgblock_three21:[
                    {tb_image:require('../assets/hpc_gn21.jpg')},
                    {tb_image:require('../assets/hpc_gn22.jpg')},
                    {tb_image:require('../assets/hpc_gn23.jpg')},
                ],
                gnmrow21:[
                    {tb_image:require('../assets/hpc_gn24.png'),tb_title:'智慧照明',tb_summary:'主要包括道路照明、园区照明、楼宇照明等，基于路照明为基础应用，建设共享资源平台，主要提供电力资源和网络资源云服务。'},
                    {tb_image:require('../assets/hpc_gn25.png'),tb_title:'能源中心',tb_summary:'实现能源的精细化管理，全面改善能源绩效，获得能源收益最大化。实时监控能耗情况，实现数据的集中存储、分析与管理。'},
                    {tb_image:require('../assets/hpc_gn26.png'),tb_title:'公共机构节能解决方案',tb_summary:'“辉腾节能管理”创新管理模式，降低正常应用节能控制，以全新理念提出了“公共机构节能解决方案”，响应国家节能减排政策。'},
                ],
                gnmrow22:[
                    {tb_image:require('../assets/hpc_gn27.png'),tb_title:'屋顶光伏发电',tb_summary:'接入配电网，发电用电并存，且要求尽可能地就地消纳。'},
                    {tb_image:require('../assets/hpc_gn28.png'),tb_title:'太阳能照明',tb_summary:'带有太阳能板和逆变器的路灯，白天利用太阳能发电把电并入市电网。'},
                    {tb_image:require('../assets/hpc_gn29.png'),tb_title:'环境监测',tb_summary:'集污染物在线监测、监控于一体；监测废水、废气中的染因子；实现实时查询、显示发布、告警等功能；确保园区污染达标排放。'},
                ],
                gnmrow23:[
                    {tb_image:require('../assets/hpc_gn2a.png'),tb_title:'园区广播',tb_summary:'为园区提供背景音乐和广播节目，并兼做紧急广播，与消防报警系统联动。'},
                ],

                imgblock_three51:[
                    {tb_image:require('../assets/hpc_gn51.jpg')},
                    {tb_image:require('../assets/hpc_gn52.jpg')},
                    {tb_image:require('../assets/hpc_gn53.jpg')},
                ],
                gnmrow51:[
                    {tb_image:require('../assets/hpc_gn54.png'),tb_title:'一卡通',tb_summary:'以非接触式IC卡和软件平台为核心，将园区内的人、财、物的管理和服务全面纳入“园区一卡通”平台，实现园区内部的身份认证和财务结算的统一管理。'},
                    {tb_image:require('../assets/hpc_gn55.png'),tb_title:'O2O',tb_summary:'构建物业公司自主运营的O2O系统；为园区工作人员日常生活提供便利的工具；多入口、多渠道增加物业公司经营收入；多终端商家后台管理系统，方便快捷。'},
                ],

                hpc_dp:require('../assets/hpc_jg2.jpg'),

                alrows:[
                    {tb_image:require('../assets/hec_al1.jpg'),tb_title:'四川阿坝1MW扶贫光伏项目'},
                    {tb_image:require('../assets/hec_al2.jpg'),tb_title:'四川理塘6MW扶贫光伏项目'},
                    {tb_image:require('../assets/hec_al3.jpg'),tb_title:'重庆巫溪整县扶贫光伏项目'},
                ],
                hpc_ys: require('../assets/hpc_ys.png'),
                address: require('../assets/address.png'),

            }
        },
        components: {
            gnmrow,
            imgblock_three,
            al_three,
        },
        mounted() {
            this.$parent.hpcbanner()
        },
        methods: {

        },
        watch: {

        },
    }
</script>
<style lang="css" scoped>
    .hpctemplate{
        color: #202020;
        text-align: left;
    }
    .txt18{
        font-size: 18px;
        color:rgba(20,20,20,0.8);
        line-height: 32px;
    }
    .txt42{
        font-size: 42px;
        color:rgba(20,20,20,1);
        line-height: 42px;
    }
    .txt60{
        font-size: 60px;
        color:rgba(20,20,20,1);
        line-height: 60px;
    }

    .hpc_ghblock{
        margin-top: 60px;
        /*height: 940px;*/
        /*border: 1px solid red;*/
    }

    .txtH16{
        font-size: 16px;
        color:rgba(20,20,20,0.8);
        line-height: 80px;
    }
    .hpc_fa1{
        background-image: url('~assets/hpc_jg1.jpg');
        width: 860px;
        height: 338px;
    }
    .hpc_fa2{
        background-image: url('~assets/hpc_jg2.jpg');
        width: 1200px;
        height: 706px;
    }
    .ghblockclick{
        cursor:pointer;
    }
    .btLine{
        border-bottom:2px solid #d7000f;
        padding-bottom: 28px;
    }

    .block3{
        width: 374px;
        height: 270px;
        border-radius: 10px;
    }



    .hpcgl_tdd{
        width: 320px;

        /*border: 1px solid red;*/
    }
    .hpcgl_tddh{
        font-size: 18px;
        font-weight: bold;
        color: rgba(20,20,20,0.9);
        margin-top: 30px;
    }
    .hpcgl_tddt{
        font-size: 16px;
        color: rgba(20,20,20,0.8);
        margin-top: 20px;
        line-height: 20px;
    }

</style>
